export default {
  siteTitle: 'Get bonus',
  blogerName: 'NALLA',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/nallaslots'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Nallinha12/videos'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/nallinha01/'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c7f7fa06f',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/cb9f96f22',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c2210633d',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c97ce9945',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c902a312d',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/ca742b70d',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c53116016',
      gameTitle: 'Jet Air'
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/ccc11c418',
      gameTitle: 'LEGZO PUNK'
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/ce144b258',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/c8ec3d844',
      gameTitle: 'STARDA QUEEN',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>NALLA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'NALLA',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
